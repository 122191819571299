html {
   height: 100%;
}

body {
    position: relative;  /* for left bar */
    margin: 0;
    padding: 0;
    min-height: 100%;
    font-family: sans-serif;
}

#root {
    min-height: 100vh;
}
